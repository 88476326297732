import { filter, flatMap, pluck, } from 'rxjs/operators';
import { of, } from 'rxjs';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, } from '@ezugi/constants';
import cardsActions from '../../actions/cards';
import { numberOfCardsSelector, } from '../../selectors/cards';
import { TOTAL_CARDS_NUMBER, } from '../../../constants/cards';

const {
  roundActions: { round, },
} = bootstrapActions;

const cardsResetEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS
    && numberOfCardsSelector(state$.value) === TOTAL_CARDS_NUMBER),
  flatMap(() => of(cardsActions.cards.reset()))
);

export default cardsResetEpic;
